"use client";

import { createContext, useContext, useEffect, useState, ReactNode } from 'react';

interface ResponsiveContextType {
  isMobile: boolean;
  isLandscape: boolean;
}

const ResponsiveContext = createContext<ResponsiveContextType>({
  isMobile: false,
  isLandscape: false
});

export const useResponsive = () => useContext(ResponsiveContext);

interface ResponsiveLayoutProps {
  children: ReactNode;
}

export default function ResponsiveLayout({ children }: ResponsiveLayoutProps) {
  const [isMobile, setIsMobile] = useState(false);
  const [isLandscape, setIsLandscape] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      
      // Check if device is in portrait and width is mobile-sized
      const isPortraitMobile = width <= 768;
      
      // Check if device is in landscape and has a height typical of mobile devices
      const isLandscapeMobile = width > 768 && height <= 480;

      // Set landscape state
      setIsLandscape(width > height && (isPortraitMobile || isLandscapeMobile));
      
      setIsMobile(isPortraitMobile || isLandscapeMobile);
    };
    
    // Initial check
    checkMobile();
    
    // Add event listeners
    window.addEventListener('resize', checkMobile);
    window.addEventListener('orientationchange', checkMobile);
    
    // Cleanup
    return () => {
      window.removeEventListener('resize', checkMobile);
      window.removeEventListener('orientationchange', checkMobile);
    };
  }, []);

  return (
    <ResponsiveContext.Provider value={{ isMobile, isLandscape }}>
      {children}
    </ResponsiveContext.Provider>
  );
}
