"use client";

import { useState } from "react";
import { Button } from "@/components/ui/Button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";

export default function JoinRoomForm() {
  const [roomId, setRoomId] = useState("");

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const normalizedRoomId = roomId.trim().toLowerCase();
    if (normalizedRoomId) {
      const encodedRoomId = encodeURIComponent(normalizedRoomId);
      window.location.href = `/room/${encodedRoomId}`;
    }
  };

  return (
    <Card className="w-full max-w-md mx-auto bg-white/10 backdrop-blur-sm border-0">
      <CardContent className="p-4">
        <form onSubmit={handleSubmit} className="space-y-4">
          <Input
            type="text"
            placeholder="Enter room name"
            value={roomId}
            onChange={(e) => setRoomId(e.target.value)}
            className="w-full h-12 text-lg bg-white/20 border-0 placeholder:text-white/50 text-white"
          />
          <Button 
            type="submit" 
            className="w-full h-12 text-lg bg-pink-500 hover:bg-pink-600 text-white border-0"
          >
            Join Room
          </Button>
        </form>
      </CardContent>
    </Card>
  );
}
