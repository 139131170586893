import { Card, CardContent } from "@/components/ui/card";

interface FeatureBoxProps {
  icon: string;
  title: string;
  description: string;
}

export default function FeatureBox({ icon, title, description }: FeatureBoxProps) {
  return (
    <Card className="bg-white/10 backdrop-blur-sm border-0 text-white">
      <CardContent className="p-6 flex flex-col items-center text-center">
        <div className={`${icon} w-12 h-12 mb-4 text-cyan-200`} aria-hidden="true">
          {icon === 'icon-video' && (
            <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
              <path d="M23 7l-7 5 7 5V7z" />
              <rect x="1" y="5" width="15" height="14" rx="2" ry="2" />
            </svg>
          )}
          {icon === 'icon-game' && (
            <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
              <rect x="2" y="4" width="20" height="16" rx="2" />
              <path d="M12 8v8M8 12h8" />
            </svg>
          )}
          {icon === 'icon-customize' && (
            <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
              <path d="M12 20h9M16.5 3.5a2.12 2.12 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z" />
            </svg>
          )}
        </div>
        <h3 className="text-xl font-semibold mb-2 text-cyan-200">{title}</h3>
        <p className="text-white/70">{description}</p>
      </CardContent>
    </Card>
  );
}
